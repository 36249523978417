<template>
  <v-dialog v-model="show">
    <v-card>
      <v-carousel v-model="carousel" hide-delimiters continuous height="90vh">
        <v-carousel-item
          v-for="project in projects"
          :src="assetUrl(project.image.private_hash)"
          :key="'ci-' + project.id"
          contain
        >
          <v-toolbar color="white" light>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title class="project-title"
                  ><span class="title-part">{{ project.title }}</span
                  ><v-chip v-if="project.sold" label x-small outlined color="primary">{{ project. sold }}</v-chip></v-list-item-title
                >
                <v-list-item-subtitle>{{ project.technique }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-spacer></v-spacer>
              <v-list-item-content>
                <v-list-item-title class="text-right">{{ project.size }}</v-list-item-title>
                <v-list-item-subtitle>&nbsp;</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-spacer></v-spacer>
            <v-btn icon @click="show = false" class="close">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>

          <div class="project-description" v-html="project.description"></div>
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    projects: Array,
    currentProject: Number
  },

  data: () => ({}),

  computed: {
    show: {
      get() {
        // on opening dialog
        /*
        if (this.value) {
        }
        */

        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },

    carousel: {
      // find index by currentProject
      get() {
        let index = 0
        for (let project of this.projects) {
          if (project.id == this.currentProject) return index

          index++
        }
        return index
      },
      // eslint-disable-next-line
      set(value) {
        // not needed to set value for carousel model
      }
    }
  },

  methods: {
    assetUrl(hash) {
      return `${process.env.VUE_APP_API_URL}/assets/${hash}`
    }
  }
}
</script>

<style scoped>
/* reduce image and place it below toolbar */
.v-carousel >>> .v-carousel__item .v-image__image {
  top: 56px;
  height: calc(100% - 56px);
}

.project-description {
  color: white;
  background: rgba(0, 0, 0, 0.5);
  bottom: 0;
  position: absolute;
  padding: 5px;
}

.project-title {
  display: flex;
  align-items: center;
}

.title-part {
  margin-right: 5px;
}
</style>
