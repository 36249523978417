<template>
    <v-container fluid class="pa-0 primary lighten-4">
      <v-row no-gutters>
        <v-col>
          <v-container class="fixed-width mb-8 gallery">
            <v-row>
              <v-col cols="12">
                <h2 id="portfolio" class="display-1 text-center mb-4">Portfolio</h2>
              </v-col>
            </v-row>

            <v-row v-for="(row, i) in griddedPictures" :key="'row2-' + i" dense>
              <v-col
                v-for="(col, j) in row"
                :key="'p-col2-' + j"
                :cols="col.cols"
                :sm="col.smCols"
                class="d-flex flex-column"
              >
                <project-card
                  v-for="picture in col.pictures"
                  :id="Number(picture.id)"
                  :key="'ppic-' + picture.id"
                  :asset-id="picture.image.private_hash"
                  :title="picture.title"
                  :size="picture.size"
                  :technique="picture.technique"
                  :description="picture.description"
                  :sold="picture.sold"
                  :ratio="toRatio(picture.image.width, picture.image.height)"
                  v-on:open-gallery="openGallery(picture.id)"
                ></project-card>
              </v-col>
            </v-row>

          </v-container>
        </v-col>
      </v-row>

      <project-dialog
        v-model="showProjectDialog"
        :projects="pictures"
        :currentProject="currentProject"
      ></project-dialog>
    </v-container>
</template>

<script>
import ProjectDialog from '@/components/ProjectDialog'
import ProjectCard from '@/components/ProjectCard'

export default {
  name: 'Home',

  components: {
    ProjectDialog,
    ProjectCard
  },

  data: () => ({
    showProjectDialog: false,
    currentProject: -1,
    pictures: [],
  }),

  computed: {
    /**
     * Returns the pictures in a multidimensional array, based on the grid definition
     * [
     *   [
     *     { defaultCols: 12, smCols: 5, pictures: [ pictureObject ] },
     *     { defaultCols: 12, smCols: 7, pictures: [ pictureObject, ... ] }
     *   ],
     *   []
     * ]
     */
    griddedPictures() {
      const defaultCols = 12
      let rows = []

      // build array with all row numbers (first element of grid)
      let rowNumbers = []
      this.pictures.forEach(item => {
        let rowNumber = (item.grid && item.grid.split(':')[0]) || null
        if (rowNumber) {
          if (!rowNumbers.includes(rowNumber)) {
            rowNumbers.push(rowNumber)
          }
        }
      })

      // iterate over each row and build the grid in the row
      rowNumbers.forEach(rowNumber => {
        let rowItems = []
        let colNumbers = []
        let colIterator = 1
        let combinedPictures = false // default is 1 picture in 1 col

        // get the pictures of the row
        let rowPictures = this.pictures.filter(item => item.grid && item.grid.startsWith(rowNumber + ':'))

        /**
         * find out, if cols need to be filled with multiple pictures or not:
         *
         * 2:6:1  -> multiple pictures in a col
         * 2:6    -> 1 picture in 1 col
         */
        rowPictures.forEach(picture => {
          let colNumber = picture.grid.split(':')[2]
          if (colNumber) {
            combinedPictures = true
          } else {
            colNumber = colIterator
          }
          colIterator++

          if (!colNumbers.includes(colNumber)) {
            colNumbers.push(colNumber)
          }
        })

        // if multiple pictures in a col, collect them and add,
        // otherwise add each in a separate row
        if (combinedPictures) {
          colNumbers.forEach(colNumber => {
            let colPictures = rowPictures.filter(item => item.grid && item.grid.split(':')[2] == colNumber)
            let smCols = rowPictures[0].grid.split(':')[1] || defaultCols
            rowItems.push({ cols: defaultCols, smCols: smCols, pictures: colPictures })
          })
        } else {
          rowPictures.forEach(picture => {
            let smCols = picture.grid.split(':')[1] || defaultCols
            rowItems.push({ cols: defaultCols, smCols: smCols, pictures: [picture] })
          })
        }

        rows.push(rowItems)
      })

      return rows
    }
  },

  mounted() {
    this.loadPictures()
  },

  methods: {
    openGallery(id) {
      this.currentProject = id
      this.showProjectDialog = true
    },

    loadPictures() {
      const url = '/items/pictures?fields=id,sort,grid,title,description,technique,size,sold,image.width,image.height,image.private_hash'

      this.axios
        .get(url)
        .then(response => {
          this.pictures = response.data.data
        })
        .catch(error => {
          console.error(error) // eslint-disable-line
        })
    },

    toRatio(width, height) {
      return (width / height)
    }
  }
}
</script>

<style scoped>
/* when more than one card in col, add margin */
.container.gallery .v-card:not(:last-child) {
  margin-bottom: 8px;
}
</style>
