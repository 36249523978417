import Vue from "vue"

import router from "../router"

import VueAnalytics from "vue-analytics"

Vue.use(VueAnalytics, {
  id: "UA-153613126-1",
  router,
  set: [
    { field: "anonymizeIp", value: true }
  ]
})
