<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      fixed
      app
      disable-resize-watcher
    >
      <v-list>

        <v-list-item>
          <v-list-item-title class="title">
            <v-img src="colorfoundry-logo_light.svg"></v-img>
          </v-list-item-title>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item :to="{ path: '/', hash: '#top' }">
          <v-list-item-content>Home</v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ path: '/', hash: '#portfolio' }">
          <v-list-item-content>Portfolio</v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ path: '/', hash: '#biography' }">
          <v-list-item-content>Biography</v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ path: '/', hash: '#awards' }">
          <v-list-item-content>Awards</v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ path: '/', hash: '#exhibitions' }">
          <v-list-item-content>Exhibitions</v-list-item-content>
        </v-list-item>

        <v-list-item :to="{ path: '/', hash: '#contact' }">
          <v-list-item-content>Contact</v-list-item-content>
        </v-list-item>

      </v-list>
    </v-navigation-drawer>

    <v-app-bar
      fixed
      elevate-on-scroll
      v-scroll="onScroll"
      :class="{ transparent: !isWhite }"
      :dark="!isWhite"
      >
      <v-app-bar-nav-icon
        class="hidden-sm-and-up"
        @click.stop="drawer = !drawer"
        light
        ></v-app-bar-nav-icon>

      <v-toolbar-title class="headline" :class="{ dark: !isWhite}">
        <router-link :to="{ path: '/', hash: '#top' }" class="toolbar-logo">
          <v-img src="colorfoundry-logo_light.svg" contain width="250" class="hidden-sm-only mr-4"></v-img>
          <v-img src="colorfoundry-logo.svg" contain width="36" class="d-none d-sm-flex d-md-none mr-4"></v-img>
        </router-link>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <nav class="hidden-xs-only d-sm-flex flex-nowrap">
        <!--
        <v-btn
          text
          large
          class="ml-2"
          :to="{ path: '/', hash: '#top' }"
          >Home</v-btn>
        -->
        <v-btn
          text
          small
          class="ml-2"
          color="secondary"
          :to="{ path: '/', hash: '#portfolio' }"
          >Portfolio</v-btn>
        <v-btn
          text
          small
          color="secondary"
          class="ml-2"
          :to="{ path: '/', hash: '#biography' }"
          >Biography</v-btn>
        <v-btn
          text
          small
          color="secondary"
          class="ml-2"
          :to="{ path: '/', hash: '#awards' }"
          >Awards</v-btn>
        <v-btn
          text
          small
          class="ml-2"
          color="secondary"
          :to="{ path: '/', hash: '#exhibitions' }"
          >Exhibitions</v-btn>
        <v-btn
          text
          small
          class="ml-2"
          color="secondary"
          :to="{ path: '/', hash: '#contact' }"
          >Contact</v-btn>
      </nav>

    </v-app-bar>
  </div>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    drawer: false,
    isWhite: false
  }),

  methods: {
    onScroll() {
      this.isWhite = window.scrollY > 30
    }
  }
};
</script>

<style scoped>
.v-toolbar >>> .v-toolbar__content {
  max-width: 960px;
  margin: 0 auto;
}

nav .theme--light.v-btn {
  color: #000 !important;
}
nav .theme--dark.v-btn {
  background-color: rgba(77, 92, 104, 0.67) !important;
  color: #fff !important;
}

.v-toolbar .toolbar-logo {
  color: black;
  text-decoration: none;
}
.v-toolbar.theme--dark .toolbar-logo {
  color: white;
}
</style>
