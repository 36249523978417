<template>
  <div>
    <app-stage asset="/assets/stages/stage-2.jpg"></app-stage>

    <news-content></news-content>

    <project-gallery></project-gallery>

    <biography-content></biography-content>

    <awards-content></awards-content>

    <exhibition-content></exhibition-content>

    <contact-content></contact-content>
  </div>
</template>

<script>

import AppStage from "@/components/layout/AppStage"

import BiographyContent from "@/components/BiographyContent"
import NewsContent from "@/components/NewsContent"
import ContactContent from "@/components/ContactContent"
import ExhibitionContent from "@/components/ExhibitionContent"
import AwardsContent from "@/components/AwardsContent"

import ProjectGallery from "@/components/ProjectGallery"

export default {
  name: 'Home',

  components: {
    AppStage,
    BiographyContent,
    NewsContent,
    ProjectGallery,
    ExhibitionContent,
    AwardsContent,
    ContactContent
  }
}
</script>
