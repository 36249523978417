import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        title: "ColorFoundry",
        metaTags: [
          { name: "description", content: "Noemi Galavotti combines different materials and techniques on colorful canvas inspired by unexplored places and imaginary dimensions." },
          { property: "og:description", content: "Noemi Galavotti combines different materials and techniques on colorful canvas inspired by unexplored places and imaginary dimensions." }
        ]
      }
    },
    {
      path: '/imprint',
      name: 'imprint',
      component: () => import(/* webpackChunkName: "imprint" */ './views/Imprint.vue'),
      meta: {
        title: "Imprint | ColorFoundry",
        metaTags: [
          { name: "description", content: "Imprint Colorfoundry" },
          { property: "og:description", content: "Imprint Colorfoundry" }
        ]
      }
    }
  ],

  scrollBehavior(to) {
    if (to.hash) {
      if (to.hash == "#top") { return { y: 0 } }
      return { selector: to.hash, offset: { y: 70 } }
    }
  },

})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title)
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)
  //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags)

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el))

  if (!nearestWithMeta) return next()

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta')

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    })

    tag.setAttribute('data-vue-router-controlled', '')

    return tag
  })
    .forEach(tag => document.head.appendChild(tag))

  next()
})

export default router
