import '@mdi/font/css/materialdesignicons.css'
import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import colorfoundryTheme from './vuetify/theme'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: { light: colorfoundryTheme }
  },
  breakpoint: {
    xlOnly: false
  }
})
