import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import './plugins/analytics'
// import VueWebP from './plugins/webp'

import './sass/common.scss'

Vue.config.productionTip = false

// Vue.use(VueWebP)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
