//import colors from "vuetify/lib/util/colors"

/**
 * Generated from image stripes95.jpg
 * https://coolors.co/a38966-142d2e-2a2607-697e99-0c5c65
 */
export default {
  primary: {
    base: "#D5A021",
    darken1: "#AF831C",
    lighten1: "#DCB149",
    lighten2: "#E4C271",
    lighten3: "#EBD39A",
    lighten4: "#F3E5C2",
    lighten5: "#FBF6EA",
  },

  secondary: {
    base: "#8EB2BE",
    lighten1: "#A2C0C9",
    lighten2: "#B7CED5",
    lighten3: "#CBDCE1",
    lighten4: "#D5E3E7",
    lighten5: "#eef4f6",
    darken3: "#4E6268",
    darken4: "#344146",
    darken5: "#1A2123"
  },

  "cf-green": {
    base: "#e1f4e9"
  }

  /*
  "blue-gray": {
    base: ""
  }
  */
}
