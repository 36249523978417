<template>
  <v-img :src="asset" :height="height">
    <v-row align="center" class="fill-height">
      <v-col class="">
        <v-img src="colorfoundry-logo_light.svg" contain max-width="300" width="300" class="mx-auto d-none d-sm-flex d-md-none"></v-img>
      </v-col>
    </v-row>
  </v-img>
</template>

<script>

export default {
  props: {
    asset: String,
    height: {
      type: Number,
      default: 300
    }
  }
}
</script>

<style scoped>
.v-image >>> .v-image__image {
  background-position: center 25% !important;
}
</style>
