<template>
  <v-container fluid class="pa-0 secondary lighten-5">
    <v-row no-gutters>
      <v-col>
        <v-container class="fixed-width">
          <v-row>
            <v-col>
              <h2 id="exhibitions" class="display-1 text-center mb-4">Exhibitions</h2>

              <v-list color="transparent">
                <template v-for="(link, index) in exhibitions">
                  <v-list-item :key="'link-lst-' + link.id" :href="link.url" target="_new">
                    <v-list-item-content class="primary--text text--darken-1">{{ link.title }}</v-list-item-content>
                    <v-list-item-icon>
                      <v-icon color="primary darken-1">mdi-open-in-new</v-icon>
                    </v-list-item-icon>
                  </v-list-item>
                  <v-divider v-if="index + 1 < exhibitions.length" :key="'link-divider-' + index"></v-divider>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    exhibitions: []
  }),

  mounted() {
    this.loadExhibitions()
  },

  methods: {
    loadExhibitions() {
      const url = '/items/exhibitions?fields=id,title,url'

      this.axios
        .get(url)
        .then(response => {
          this.exhibitions = response.data.data
        })
        .catch(error => {
          console.error(error) // eslint-disable-line
        })
    }
  }
}
</script>
