<template>
  <v-container fluid class="pa-0 cf-green">
    <v-row no-gutters>
      <v-col>
        <v-container class="fixed-width">
          <v-row>
            <v-col>
              <h2 id="awards" class="display-1 text-center mb-4">Awards</h2>

              <v-list color="transparent">
                <template v-for="(award, index) in awards">
                  <v-list-item :key="'award-lst-' + award.id" :to="award.picture ? { path: '/', hash: '#project-' + award.picture.id } : null">
                    <v-list-item-avatar>
                      <v-img v-if="award.picture" :src="projectAsset(award.picture.image.private_hash)"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content class="">{{ award.title }}</v-list-item-content>

                    <v-list-item-content v-if="award.image">
                      <v-img :src="awardAsset(award.image.private_hash)" contain max-height="200"></v-img>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider v-if="index + 1 < awards.length" :key="'award-divider-' + index"></v-divider>
                </template>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    awards: []
  }),

  mounted() {
    this.loadAwards()
  },

  methods: {
    loadAwards() {
      const url = '/items/awards?fields=id,sort,title,image.private_hash,picture.id,picture.image.private_hash'

      this.axios
        .get(url)
        .then(response => {
          this.awards = response.data.data
        })
        .catch(error => {
          console.error(error) // eslint-disable-line
        })
    },

    awardAsset(hash) {
      return `${process.env.VUE_APP_API_URL}/assets/${hash}?key=gallery`
    },

    projectAsset(hash) {
      return `${process.env.VUE_APP_API_URL}/assets/${hash}?key=preview`
    }
  }
}
</script>

<style scoped>
.v-list-item__avatar >>> .v-image__image {
  background-size: 500%;
}
</style>
