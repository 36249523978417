<template>
  <v-app>
    <app-navigation/>

    <v-content>
      <router-view/>
    </v-content>

    <app-footer></app-footer>
  </v-app>
</template>

<script>

import AppNavigation from "@/components/layout/AppNavigation"
import AppFooter from "@/components/layout/AppFooter"

export default {
  name: 'App',

  components: {
    AppNavigation,
    AppFooter
  },

  data: () => ({
    //
  }),

  methods: {
  }
};
</script>
