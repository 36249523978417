<template>
  <v-card tile flat class="d-flex flex-column flex-grow-1" :id="'project-' + id">
    <v-img :src="assetUrl" :lazy-src="lazyAssetUrl" :aspect-ratio="ratio" contain position="top center"></v-img>
    <v-card-title>{{ title }}</v-card-title>
    <v-card-subtitle class="pb-0">{{ size }}</v-card-subtitle>
    <v-card-text class="text--primary">{{ technique }}</v-card-text>
    <v-card-actions class="">
      <v-btn color="primary" text @click="openGallery">Zoom</v-btn>
      <v-spacer></v-spacer>
      <v-btn icon @click="expanded = !expanded">
        <v-icon>{{ expanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="expanded">
        <v-divider></v-divider>

        <v-card-text v-html="description"></v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  props: {
    id: Number,
    index: Number,
    title: String,
    size: String,
    technique: String,
    description: String,
    ratio: Number,
    assetId: String
  },

  data: () => ({
    expanded: false
  }),

  mounted() {},

  methods: {
    openGallery() {
      this.$emit('open-gallery')
    }
  },

  computed: {
    assetUrl() {
      return `${process.env.VUE_APP_API_URL}/assets/${this.assetId}?key=gallery`
    },

    lazyAssetUrl() {
      return `${process.env.VUE_APP_API_URL}/assets/${this.assetId}?key=preview`
    }
  }
}
</script>
