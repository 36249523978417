<template>
  <v-container class="fixed-width" v-if="news.length > 0">
    <v-row>
      <v-col>
        <h2 id="about" class="display-1 text-center mb-4">News</h2>

        <template v-for="(entry, index) in news">
          <v-row align="center" justify="center" :key="'news-entry-' + entry.id">
            <v-col>
              <p class="font-weight-black title text-center" v-html="entry.content"></p>
            </v-col>
            <v-col>
              <a v-if="entry.image" :href="entry.url" target="_new" class="headline">
                <v-img :src="newsAsset(entry.image.private_hash)" contain></v-img>
              </a>
              <a v-else-if="entry.url" :href="entry.url" target="_new" class="headline">
                {{ niceUrl(entry.url) }}
              </a>
            </v-col>
          </v-row>
          <v-divider v-if="index + 1 < news.length" :key="'news-divider-' + index"></v-divider>
        </template>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    news: []
  }),

  mounted() {
    this.loadNews()
  },

  methods: {
    loadNews() {
      const url = '/items/news?fields=id,content,url,image.private_hash'

      this.axios
        .get(url)
        .then(response => {
          this.news = response.data.data
        })
        .catch(error => {
          console.error(error) // eslint-disable-line
        })
    },

    newsAsset(hash) {
      return `${process.env.VUE_APP_API_URL}/assets/${hash}`
    },

    niceUrl(url) {
      let domain = url
      return domain
        .replace('http://', '')
        .replace('https://', '')
        .split(/[/?#]/)[0]
    }
  }
}
</script>
